export default (axios, checkHeaders) => ({
  absences (data = {}) {
    return axios.post('/export/absences', data.body, { params: data.filters || {} })
  },
  calendarEvents (data = {}) {
    return axios.post('/export/calendar-events', data.body, { params: data.filters || {} })
  },
  contacts (data = {}) {
    return axios.post('/export/contacts', data.body, { params: data.filters || {} })
  },
  notificationsListEvents (data = {}) {
    return axios.post('/export/notifications-list-events', data.body, { params: data.filters || {} })
  },
  products (data = {}) {
    return axios.post('/export/products', data.body, { params: data.filters || {} })
  },
  purchases (data = {}) {
    return axios.post('/export/purchases', data.body, { params: data.filters || {} })
  },
  purchasesPdf (data = {}) {
    return axios.post('/export/purchases/pdf', data.body, { params: data.filters || {} })
  },
  invoices (data = {}) {
    return axios.post('/export/invoices', data.body, { params: data.filters || {} })
  },
  purchasesFEC (data = {}) {
    return axios.post('/export/purchases/fec', data.body, { params: data.filters || {} })
  },
  invoicesFEC (data = {}) {
    return axios.post('/export/invoices/fec', data.body, { params: data.filters || {} })
  },
})
