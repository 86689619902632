export var hasRight = (position, rightName, all = false) => {
  if (!rightName) return true

  if (position.teams && position.teams.length > 0) {
    let isAdmin = false
    position.teams.forEach(p => {
      if (p.rightType === 'admin') isAdmin = true
    })
    if (isAdmin) return true
  }

  // On peut passer un droit en chaine de charactère ou un tableau de droits à vérifier, dans tous les cas à la fin on doit vérifier un tableau de droits
  let rightsNeeded
  if (typeof rightName === 'string') {
    rightsNeeded = [rightName]
  } else {
    rightsNeeded = rightName
  }

  let has = !!all
  let done = false
  const rights = position.rights || []

  rightsNeeded.forEach(rightName => {
    if (!done) {
      const foundRight = rights.find(r => r.name === rightName)
      const check = foundRight ? foundRight.checked || false : false
      if (all && !check) {
        done = true // Do not check any further its definitly false
        has = false
      }
      if (!all && check) {
        done = true
        has = true
      }
    }
  })
  return has
}

export const checkRights = (param = {}, position) => {
  if (position.teams && position.teams.length > 0) {
    let isAdmin = false
    position.teams.forEach(p => {
      if (p.rightType === 'admin') isAdmin = true
    })
    if (isAdmin) return true
  }

  if (!param.needRights) return true

  let allow = true
  if (param.needRights.length === 0) {
    // If at least one right on group is checked, we display the module
    allow = position.rights ? position.rights.findIndex(r => {
      if (r.group === param.group && r.checked === true) {
        return r
      }
    }) !== -1 : false
  } else {
    if (param.orRight) allow = false
    param.needRights.forEach((right) => {
      const has = hasRight(position, right)
      if (!param.orRight) {
        allow = (allow && has)
      } else {
        allow = (allow || has)
      }
    })
  }

  return allow
}

export const checkRightsAndRedirect = (rightNames, position, next, routeRedirect = { name: 'Error403' }) => {
  if (!rightNames) return

  if (!Array.isArray(rightNames)) {
    rightNames = [rightNames]
  }

  if (!hasRight(position, rightNames)) {
    return next(routeRedirect)
  }
}

export const hasAdminType = (position) => {
  let isAdmin = false
  if (position.teams && position.teams.length > 0) {
    position.teams.forEach(p => {
      if (p.rightType === 'admin') isAdmin = true
    })
  }
  return isAdmin
}

export const getRightTypesId = (position) => {
  let ids = []

  if (position.teams && position.teams.length > 0) {
    position.teams.forEach(p => {
      if (ids.findIndex(id => id === p.rightType) < 0) ids.push(p.rightType)
    })
  }

  return ids
}

export const RIGHTS = {
  // COMPANY
  MANAGE_COMPANY: 'manageCompany',
  MANAGE_FISCAL: 'manageFiscal',
  // TEAMS & USERS
  SEE_MY_TEAMS: 'seeMyTeam',
  SEE_ALL_TEAMS: 'seeUsers',
  SEE_ALL_USERS: 'seeUsers',
  SEE_COLLABORATORS: 'seeTeam',
  UPDATE_USER: 'updateUserInfos',
  SEE_ALL_USER_INFOS: 'seeAllUsersInfos',
  MANAGE_RIGHTS: 'manageUsers',
  MANAGE_TEAMS: 'manageTeams',
  INVITE_USER: 'inviteUser',
  // PRODUCTS
  SEE_MY_PRODUCTS: 'seeMyProducts',
  SEE_TEAM_PRODUCTS: 'seeTeamProducts',
  SEE_ALL_PRODUCTS: 'manageProducts',
  CREATE_PRODUCT: 'addProduct',
  UPDATE_PRODUCT: 'addProduct',
  // PURCHASES
  SEE_ALL_PURCHASES: 'managePurchases',
  CREATE_PURCHASE: 'addPurchase',
  UPDATE_PURCHASE: 'addPurchase',
  // BASKET
  SEE_MY_BASKETS: 'seeMyBaskets',
  SEE_ALL_BASKETS: 'seeAllBaskets',
  CREATE_BASKET: 'addBasket',
  WRITE_BASKET: 'addBasket',
  // EXPORTS
  MANAGE_EXPORTS: 'manageExports',
  // INVOICES
  SEE_MY_INVOICES: 'seeMyInvoices',
  SEE_TEAM_INVOICES: 'seeTeamInvoices',
  SEE_ALL_INVOICES: 'manageInvoices',
  CREATE_INVOICE: 'addInvoice',
  UPDATE_INVOICE: 'addInvoice',
  // QUOTES
  SEE_MY_QUOTES: 'seeMyQuotes',
  SEE_TEAM_QUOTES: 'seeTeamQuotes',
  SEE_ALL_QUOTES: 'manageQuotes',
  CREATE_QUOTE: 'addQuote',
  UPDATE_QUOTE: 'addQuote',
  // DELIVERY RECEIPTS
  SEE_MY_DELIVERY_RECEIPTS: 'seeMyDeliveryReceipts',
  SEE_TEAM_DELIVERY_RECEIPTS: 'seeTeamDeliveryReceipts',
  SEE_ALL_DELIVERY_RECEIPTS: 'seeAllDeliveryReceipts',
  CREATE_DELIVERY_RECEIPT: 'addDeliveryReceipt',
  UPDATE_DELIVERY_RECEIPT: 'addDeliveryReceipt',
  // CONTACTS
  SEE_MY_CONTACTS: 'seeMyClients',
  SEE_TEAM_CONTACTS: 'seeTeamClients',
  SEE_ALL_CONTACTS: 'manageClients',
  CREATE_CONTACT: 'addClients',
  UPDATE_CONTACT: 'addClients',
  CREATE_ACTIVITY: 'addMyActivity',
  DELETE_ACTIVITY: 'addMyActivity',
  // API
  MANAGE_API: 'manageAPI',
  // STATS
  MANAGE_STATS: 'manageStatistics',
  // PAYROLLS
  CREATE_SAFE_FILE: 'managePayrolls',
  SEE_MY_SAFE_FILES: 'seeMyPayrolls',
  SEE_ALL_SAFE_FILES: 'seeAllPayrolls',
  CREATE_PAYROLL: 'managePayrolls',
  UPDATE_PAYROLL: 'managePayrolls',
  SEE_MY_PAYROLLS: 'seeMyPayrolls',
  SEE_ALL_PAYROLLS: 'seeAllPayrolls',
  // ABSENCES
  MANAGE_ABSENCES: 'manageAbsences',
  SEE_MY_ABSENCES: 'seeMyAbsences',
  SEE_TEAM_ABSENCES: 'seeTeamAbsences',
  SEE_ALL_ABSENCES: 'seeAllAbsences',
  CREATE_ABSENCE: 'addMyAbsences',
  CREATE_ABSENCE_TO_OTHER: 'addAbsences',
  // CALENDAR EVENT
  SEE_MY_CALENDAR_EVENTS: 'seeMyCalendarEvents',
  SEE_ALL_CALENDAR_EVENTS: 'seeAllCalendarEvents',
  CREATE_CALENDAR_EVENT: 'addCalendarEvents',
  UPDATE_CALENDAR_EVENT: 'addCalendarEvents',
  MANAGE_CALENDAR_EVENTS: 'manageCalendarEvents',
  WRITE_CALENDAR_EVENT_CATEGORY: 'addCalendarEventCategory',
  // Notifications Liste
  CREATE_NOTIFICATIONS_LIST: "addNotificationsList",
  UPDATE_NOTIFICATIONS_LIST: "addNotificationsList",
  SEE_MY_NOTIFICATIONS_LIST: "seeMyNotificationsList",
}
