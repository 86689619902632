import AppLayout from '../layouts/AppLayout'
import DocumentLayout from "../layouts/DocumentLayout"
import {resolveLoadingApp} from "../utils/middlewares/auth.middleware"
import store from "../store"

const beforeEnterCheckerSeeInvoices = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let seeMy = store.getters.currentRights.SEE_MY_INVOICES
    let seeTeam = store.getters.currentRights.SEE_TEAM_INVOICES
    let seeAll = store.getters.currentRights.SEE_ALL_INVOICES

    if (!seeMy && !seeTeam && !seeAll) return next(redirect)
  }

  next()
}

const beforeEnterCheckerAddInvoice = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.CREATE_INVOICE && !store.getters.currentRights.UPDATE_INVOICE) return next(redirect)
  }

  next()
}

export default [
  {
    path: '/:company_url/invoices',
    name: 'InvoicesList',
    component: () => import('../pages/company/invoice/InvoicesList.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeInvoices(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.invoices_menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/invoice/create-external',
    name: 'InvoiceExternalCreate',
    component: () => import('../pages/company/invoice/InvoiceExternalForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddInvoice(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.add_external', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/invoice/update-external/:id',
    name: 'InvoiceExternalUpdate',
    component: () => import('../pages/company/invoice/InvoiceExternalForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddInvoice(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.add_external', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/invoice/:id/html',
    name: 'InvoiceHtml',
    component: () => import('../pages/company/invoice/InvoiceHTML'),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isAuthenticated) {
        return next({ name: 'InvoiceDocumentHtmlWithoutCompanyUrl', params: { id: to.params.id } })
      }
      await beforeEnterCheckerSeeInvoices(to, from, next, { name: 'InvoiceDocumentHtmlWithoutCompanyUrl', params: { id: to.params.id } })

      await resolveLoadingApp(to, from, next)
    },
    // Ici il n'y a pas de authenticate true pour permettre la redirection vers la vue client si la personne n'a pas l'accès ce document en vue HTML Company
    meta: { title: 'invoice', layout: AppLayout, activeMenu: "app", company: { auth: true, redirect: (to) => ({ name: 'InvoiceDocumentHtmlWithoutCompanyUrl', params: { id: to.params.id } }) } }
  },
  {
    path: '/:company_url/invoice/:id/update',
    name: 'InvoiceUpdate',
    component: () => import('../pages/company/invoice/InvoiceForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddInvoice(to, from, next, { name: 'InvoiceDocumentHtmlWithoutCompanyUrl', params: { id: to.params.id } })

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.update', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/invoice/create',
    name: 'InvoiceCreate',
    component: () => import('../pages/company/invoice/InvoiceForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddInvoice(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.update', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/invoice/:id/pdf',
    name: 'InvoicePdf',
    component: () => import('../pages/document/DocumentPDF.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeInvoices(to, from, next, { name: 'InvoiceDocumentHtmlWithoutCompanyUrl', params: { id: to.params.id } })

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'invoice', layout: DocumentLayout, company: true }
  },
  // INVOICE TVA COUNT
  {
    path: '/:company_url/invoice/tva-count/create',
    name: 'InvoiceTvaCountCreate',
    component: () => import('../pages/company/invoiceTvaCount/InvoiceTvaCountCreate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddInvoice(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'invoice_categories.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/invoice/tva-count/update/:id',
    name: 'InvoiceTvaCountUpdate',
    component: () => import('../pages/company/invoiceTvaCount/InvoiceTvaCountUpdate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddInvoice(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'invoice_categories.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/invoice/tva-counts',
    name: 'InvoiceTvaCountList',
    component: () => import('../pages/company/invoiceTvaCount/InvoiceTvaCountList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeInvoices(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'invoices.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  // DUNNINGS
  {
    path: '/:company_url/invoice/dunnings',
    name: 'DunningsList',
    component: () => import('../pages/company/invoice/Dunnings'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeInvoices(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.invoices_menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  // RECURRENCES
  {
    path: '/:company_url/invoice/recurrences',
    name: 'InvoiceRecurrenceList',
    component: () => import('../pages/company/invoiceRecurrence/InvoiceRecurrenceList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeInvoices(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.invoices_menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/invoice/:id/send-mail',
    name: 'InvoiceSendMail',
    component: () => import('../pages/company/SendMail'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'sendmail.title_nav', layout: AppLayout, activeMenu: 'app', authenticate: true, company: true }
  },
  {
    path: '/:company_url/invoice/:id/reminder-mail',
    name: 'InvoiceReminderMail',
    component: () => import('../pages/company/SendMail'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'sendmail.title_nav', layout: AppLayout, activeMenu: 'app', authenticate: true, company: true }
  },
  // REDIRECTIONS
  {
    path: '/:company_url/invoice/pdf/:id',
    redirect: to => {
      return { name: 'InvoicePdf', params: { company_url: to.params.company_url, id: to.params.id } }
    }
  },
  {
    path: '/:company_url/invoice/html/:id',
    redirect: to => {
      return { name: 'InvoiceHtml', params: { company_url: to.params.company_url, id: to.params.id } }
    }
  },
  {
    path: '/:company_url/invoice/update/:id',
    redirect: to => {
      return { name: 'InvoiceUpdate', params: { company_url: to.params.company_url, id: to.params.id } }
    }
  }
]
