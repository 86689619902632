export default {
  deleteBox: function () {
    let title = "Êtes-vous sûr de vouloir supprimer cet élément ?"
    let callback = function () {}
    let params = {

    }

    if (arguments.length > 0) {
      for (let i = 0; i < arguments.length; i++) {
        if (typeof arguments[i] === 'string') {
          title = arguments[i]
          continue
        }
        if (typeof arguments[i] === 'function') {
          callback = arguments[i]
          continue
        }
        if (typeof arguments[i] === 'object') {
          params = {...params, ...arguments[i]}
        }
      }
    }

    this.$bvModal.msgBoxConfirm(title, {
      title: 'Confirmation',
      size: 'md',
      okVariant: 'danger',
      okTitle: params.okTitle ? params.okTitle : 'Supprimer',
      cancelVariant: 'light',
      cancelTitle: 'Annuler',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    })
      .then(value => {
        if (value === true) {
          callback(value)
        }
      })
  }
}
