import api from '../../utils/api_factory'
import to from 'await-to-js'
import {CURRENT_INVOICE} from '../mutation.constant'
import {getNewInvoice} from "@/components/Document/document.constant"

const state = {
  currentInvoice: {},
  filters: localStorage.getItem('invoiceFilters') ? JSON.parse(localStorage.getItem('invoiceFilters')) : {}
}

const getters = {
  currentInvoice: state => state.currentInvoice,
  getInvoiceFilters: () => state.filters
}

const actions = {
  $a_getCurrentInvoice: async function ({ commit, dispatch, rootState, state }, { id, documentModels, countries, client }) {
    if (!id) {
      let invoice = await getNewInvoice(rootState.company.currentCompany, rootState.authV3.currentPosition, rootState.authV3.currentUser, documentModels, countries, client)
      commit(CURRENT_INVOICE, invoice)
    } else {
      let [err, resp] = await to(api.company.invoice.getOne({ id, filters: { populate: 'dunningRule' } }))
      if (err) return err

      let invoice = resp.data.invoice
      if (!invoice.style) invoice.style = {}
      if (!invoice.style.footer) invoice.style.footer = {}
      if (!invoice.dunnings) invoice.dunnings = []

      commit(CURRENT_INVOICE, resp.data.invoice)
    }
  },
}

const mutations = {
  [CURRENT_INVOICE]: (state, invoice) => {
    state.currentInvoice = invoice
  },
  invoiceFilters (state, filters) {
    let newFilter = {}
    newFilter.state = filters.state
    newFilter.date = filters.date
    newFilter.minDate = filters.minDate
    newFilter.maxDate = filters.maxDate
    newFilter.search = filters.search
    localStorage.setItem('invoiceFilters', JSON.stringify(newFilter))
    state.filters = { ...newFilter }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
